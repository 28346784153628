import React, { useState } from "react";
import axiosInstance from "../axiosConfig";

const PasswordResetModal = ({ userId, onClose }) => {
   const [password, setPassword] = useState("");
   const [confirmPassword, setConfirmPassword] = useState("");
   const [error, setError] = useState("");

   const handleSubmit = async (e) => {
      e.preventDefault();
      if (password !== confirmPassword) {
         setError("Passwords do not match");
         return;
      }

      try {
         await axiosInstance.put(`/v1/users/update-password/${userId}`, { password });
         alert("Password updated successfully");
         onClose();
      } catch (error) {
         setError("Failed to reset password");
      }
   };

   return (
      <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center">
         <div className="bg-white p-6 rounded shadow-md w-1/3">
            <h3 className="text-2xl font-bold mb-4 text-black">Reset Password</h3>
            {error && <p className="text-red-500 mb-4">{error}</p>}
            <form onSubmit={handleSubmit}>
               <div className="mb-4">
                  <label className="block text-gray-700">New Password</label>
                  <input
                     type="password"
                     value={password}
                     onChange={(e) => setPassword(e.target.value)}
                     className="w-full px-4 py-2 border rounded text-gray-700"
                  />
               </div>
               <div className="mb-4">
                  <label className="block text-gray-700">Confirm Password</label>
                  <input
                     type="password"
                     value={confirmPassword}
                     onChange={(e) => setConfirmPassword(e.target.value)}
                     className="w-full px-4 py-2 border rounded text-gray-700"
                  />
               </div>
               <div className="flex justify-end">
                  <button type="button" className="bg-gray-500 text-white px-4 py-2 rounded mr-4" onClick={onClose}>
                     Cancel
                  </button>
                  <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
                     Reset Password
                  </button>
               </div>
            </form>
         </div>
      </div>
   );
};

export default PasswordResetModal;
