import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Используем useNavigate вместо useHistory
import axiosInstance from "../axiosConfig";
import CreateUserModal from "../components/CreateUserModal";

const Users = () => {
   const [title, setTitle] = useState("");
   const [message, setMessage] = useState("");
   const [success, setSuccess] = useState("");
   const [error, setError] = useState("");

   const navigate = useNavigate(); // Используем useNavigate для навигации

   const handleSubmit = async (e) => {
      e.preventDefault();
      try {
         const response = await axiosInstance.post("https://app.nativenotify.com/api/notification", {
            appId: 22794,
            appToken: "xs4ubKqcayxZveiqjIpORC",
            title: title,
            body: message,
            dateSent: "9-5-2024 8:51PM",
            pushData: { yourProperty: "yourPropertyValue" },
            bigPictureURL: "https://letsenhance.io/static/73136da51c245e80edc6ccfe44888a99/1015f/MainBefore.jpg",
         });
         setSuccess("Notification sent successfully!");
         setError("");
         setTitle("");
         setMessage("");
      } catch (err) {
         setError("Failed to send notification.");
         setSuccess("");
      }
   };

   const [users, setUsers] = useState([]);
   const [inputId, setInputId] = useState("");
   const [inputEmail, setInputEmail] = useState("");
   const [inputName, setInputName] = useState("");
   const [inputSurname, setInputSurname] = useState("");

   const [isModalOpen, setIsModalOpen] = useState(false);

   const fetchData = async () => {
      try {
         // Формирование запроса на сервер с несколькими фильтрами
         const response = await axiosInstance.get(`/v1/users-filter`, {
            params: {
               id: inputId || undefined,
               email: inputEmail || undefined,
               name: inputName || undefined,
               surname: inputSurname || undefined,
            },
         });
         setUsers(response.data.users ?? []);
      } catch (error) {
         console.log(error);
      }
   };

   const handleSearch = () => {
      fetchData();
   };

   const handleUserClick = (id) => {
      // Перенаправляем на страницу деталей пользователя с помощью useNavigate
      navigate(`/user-details/${id}`);
   };

   return (
      <div className="bg-white text-black min-h-screen p-6">
         <p className="text-gray-700">Pages / Users</p>
         <h2 className="text-3xl font-bold text-gray-800">Users</h2>

         <p className="text-xl font-bold text-gray-800 mt-4">Search Users</p>

         <div className="flex-row">
            {/* Поиск по ID */}
            <input
               value={inputId}
               onChange={(e) => setInputId(e.target.value)}
               placeholder="Search by ID"
               className="w-[250px] h-[36px] text-black px-2 border border-gray-300 rounded mr-4"
               type="text"
            />

            {/* Поиск по Email */}
            <input
               value={inputEmail}
               onChange={(e) => setInputEmail(e.target.value)}
               placeholder="Search by Email"
               className="w-[250px] h-[36px] text-black px-2 border border-gray-300 rounded mr-4"
               type="text"
            />

            {/* Поиск по Name */}
            <input
               value={inputName}
               onChange={(e) => setInputName(e.target.value)}
               placeholder="Search by Name"
               className="w-[250px] h-[36px] text-black px-2 border border-gray-300 rounded  mr-4"
               type="text"
            />

            {/* Поиск по Surname */}
            <input
               value={inputSurname}
               onChange={(e) => setInputSurname(e.target.value)}
               placeholder="Search by Surname"
               className="w-[250px] h-[36px] text-black px-2 border border-gray-300 rounded mr-4"
               type="text"
            />
            <button onClick={handleSearch} className="bg-blue-600 text-white py-2 px-4 rounded">
               Search
            </button>
            <button onClick={() => setIsModalOpen(true)} className="bg-yellow-400 text-white py-2 px-4 rounded ml-4">
               Create User
            </button>
         </div>

         {/* Модальное окно редактирования */}
         {isModalOpen && <CreateUserModal onClose={() => setIsModalOpen(false)} onSave={() => {}} />}

         {/* Таблица с данными о пользователях */}
         {users.length > 0 ? (
            <table className="table-auto w-full mt-6 text-gray-800 border">
               <thead>
                  <tr className="bg-gray-300">
                     <th className="px-4 py-2">ID</th>
                     <th className="px-4 py-2">Email</th>
                     <th className="px-4 py-2">Name</th>
                     <th className="px-4 py-2">Surname</th>
                     <th className="px-4 py-2">Role</th>
                     <th className="px-4 py-2">Major</th>
                     <th className="px-4 py-2">Year</th>
                     <th className="px-4 py-2">Image</th>
                  </tr>
               </thead>
               <tbody>
                  {users.map((user) => (
                     <tr key={user.id} className="border-t cursor-pointer" onClick={() => handleUserClick(user.id)}>
                        <td className="px-4 py-2">{user.id}</td>
                        <td className="px-4 py-2">{user.email}</td>
                        <td className="px-4 py-2">{user.name}</td>
                        <td className="px-4 py-2">{user.surname}</td>
                        <td className="px-4 py-2">{user.role}</td>
                        <td className="px-4 py-2">{user.major}</td>
                        <td className="px-4 py-2">{user.year}</td>
                        <td className="px-4 py-2">
                           {user.image_url ? (
                              <img src={user.image_url} alt="user" className="w-16 h-16 rounded-full" />
                           ) : (
                              "No Image"
                           )}
                        </td>
                     </tr>
                  ))}
               </tbody>
            </table>
         ) : (
            <p className="mt-6 text-gray-700">No users found.</p>
         )}

         {/* Форма для отправки уведомления */}
         {/* <form onSubmit={handleSubmit} className="bg-gray-100 text-black p-6 rounded shadow-md w-80 mt-6">
            <h3 className="text-2xl mb-4 text-gray-800">Send Notification</h3>
            
            <input
               type="text"
               placeholder="Title"
               value={title}
               onChange={(e) => setTitle(e.target.value)}
               className="mb-4 p-2 w-full border border-gray-400 text-black rounded"
               required
            />
            
            <textarea
               placeholder="Message"
               value={message}
               onChange={(e) => setMessage(e.target.value)}
               className="mb-4 p-2 w-full border border-gray-400 text-black rounded"
               required
            />
            
            <button type="submit" className="bg-blue-600 text-white py-2 px-4 rounded">
               Send Notification
            </button>
            
            {success && <p className="text-green-500 mt-4">{success}</p>}
            {error && <p className="text-red-500 mt-4">{error}</p>}
         </form> */}
      </div>
   );
};

export default Users;
