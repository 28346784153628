import React, { useState, useEffect, useMemo } from "react";
import { Line, Pie } from "react-chartjs-2";
import {
   Chart as ChartJS,
   CategoryScale,
   LinearScale,
   PointElement,
   LineElement,
   ArcElement,
   Title,
   Tooltip,
   Legend,
} from "chart.js";
import StatBlock from "../components/StatBlock";
import axiosInstance from "../axiosConfig";
import { useFilters, usePagination, useTable } from "react-table";
import ZeroClassesTable from "../components/ZeroClassesTable";

// Регистрация компонентов для Chart.js
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, ArcElement, Title, Tooltip, Legend);

const months = [
   "Jan 2024",
   "Feb 2024",
   "Mar 2024",
   "Apr 2024",
   "May 2024",
   "Jun 2024",
   "Jul 2024",
   "Aug 2024",
   "Sep 2024",
   "Oct 2024",
   "Nov 2024",
   "Dec 2024",
];

const Dashboard = () => {
   const [dashboardData, setDashboardData] = useState(null);
   const [avgAttendance, setAvgAttendance] = useState([]);
   const [studentsWithZeroClasses, setStudentsWithZeroClasses] = useState([]);
   const [sectionPie, setSectionPie] = useState([]);
   const [sectionFillRate, setSectionFillRate] = useState([]);
   const [loading, setLoading] = useState(true);

   useEffect(() => {
      const fetchData = async () => {
         try {
            const responseDashboard = await axiosInstance.get("/v1/stats/dashboard");
            const responseAvgAttendance = await axiosInstance.get("/v1/stats/avg-attendance");
            const responseSectionPie = await axiosInstance.get("/v1/stats/section-pie");
            const responseSectionFillRate = await axiosInstance.get("/v1/stats/fill-rate");
            const responseZeroClasses = await axiosInstance.get("/v1/stats/zero-classes");

            setDashboardData(responseDashboard.data ?? []);
            setAvgAttendance(responseAvgAttendance.data ?? []);
            setSectionPie(responseSectionPie.data ?? []);
            setSectionFillRate(responseSectionFillRate.data ?? []);
            setStudentsWithZeroClasses(responseZeroClasses.data.students);
            console.log(responseZeroClasses.data.students);

            setLoading(false);
         } catch (error) {
            console.error("Error fetching data", error);
         }
      };

      fetchData();
   }, []);

   const columns = useMemo(
      () => [
         { Header: "ID", accessor: "id" },
         { Header: "Name", accessor: "name" },
         { Header: "Surname", accessor: "surname" },
         { Header: "Email", accessor: "email" },
      ],
      []
   );

   const tableInstance = useTable(
      {
         columns,
         data: studentsWithZeroClasses,
         initialState: { pageSize: 6 },
      },
      useFilters,
      usePagination
   );

   const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state,
      page,
      nextPage,
      previousPage,
      canNextPage,
      canPreviousPage,
   } = tableInstance;

   if (loading) {
      return <div>Loading...</div>;
   }

   // Функция для заполнения пропущенных месяцев нулями
   const fillMissingMonths = (data, key) => {
      const filledData = months.map((month) => {
         const existingMonth = data.find((item) => item.month === month);
         return existingMonth ? existingMonth[key] : 0;
      });
      return filledData;
   };

   // Линейный график посещаемости с заполнением отсутствующих месяцев
   const attendanceData = {
      labels: months,
      datasets: [
         {
            label: "Average Attendance (%)",
            data: fillMissingMonths(avgAttendance, "average_attendance"),
            borderColor: "#2B3674",
            backgroundColor: "rgba(43, 54, 116, 0.3)",
            fill: true,
            tension: 0.4,
         },
      ],
   };

   // Круговая диаграмма секций
   const sectionData = {
      labels: sectionPie.map((item) => item.title),
      datasets: [
         {
            label: "Section Popularity",
            data: sectionPie.map((item) => item.percent),
            backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF"],
            hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF"],
         },
      ],
   };

   // Функция для подготовки данных о заполняемости секций с учетом отсутствующих месяцев
   const prepareSectionFillRateData = () => {
      const sectionFillRateDatasets = [];

      // Перебираем все секции
      sectionFillRate.forEach((item) => {
         let sectionIndex = sectionFillRateDatasets.findIndex((dataset) => dataset.label === item.section);
         if (sectionIndex === -1) {
            sectionFillRateDatasets.push({
               label: item.section,
               data: Array(12).fill(0), // Массив на 12 месяцев, заполненный нулями
               borderColor: "#" + Math.floor(Math.random() * 16777215).toString(16), // случайный цвет
               backgroundColor: "rgba(75, 192, 192, 0.2)",
               fill: false,
            });
            sectionIndex = sectionFillRateDatasets.length - 1;
         }
         const monthIndex = months.indexOf(item.month); // Получаем индекс месяца
         if (monthIndex !== -1) {
            sectionFillRateDatasets[sectionIndex].data[monthIndex] = item.fill_rate; // Заполняем данные
         }
      });

      return sectionFillRateDatasets;
   };

   // График заполняемости секций
   const sectionFillRateData = {
      labels: months, // Все месяцы
      datasets: prepareSectionFillRateData(), // Данные с учетом заполнения пропущенных месяцев
   };

   return (
      <div className="">
         <div>
            <p className="text-[#707EAE]">Pages / Dashboard</p>
            <h2 className="text-3xl font-bold text-[#2B3674]">Main Dashboard</h2>

            <div className="flex w-full mt-4">
               {/* Блоки статистики */}
               <StatBlock title={"Lessons"} content={dashboardData.total_lessons} />
               <StatBlock title={"Available Lessons"} content={dashboardData.available_lessons} />
               <StatBlock title={"Avg. Attendance"} content={`${Math.round(dashboardData.average_attendance * 100) / 100}%`} />
               <StatBlock title={"Students"} content={dashboardData.total_students} />
               <StatBlock title={"Teachers"} content={dashboardData.total_teachers} />
               <StatBlock title={"Passed Students"} content={dashboardData.students_with_ten_present} />
               <StatBlock title={"Failed Students"} content={dashboardData.students_with_five_misses} />
            </div>

            {/* Графики */}
            <div className="flex mt-6 space-x-6">
               {/* Линейный график посещаемости */}
               <div className="w-[60%] h-[400px] p-4 bg-white rounded-lg shadow-md">
                  <Line data={attendanceData} options={{ responsive: true, scales: { y: { beginAtZero: true, max: 100 } } }} />
               </div>

               {/* Круговая диаграмма секций */}
               <div className="w-[40%] h-[400px] p-4 bg-white rounded-lg shadow-md">
                  <Pie data={sectionData} />
               </div>
            </div>

            {/* График заполняемости секций */}
            <div className="w-full h-[400px] mt-6 p-4 bg-white rounded-lg shadow-md">
               <Line data={sectionFillRateData} options={{ responsive: true, scales: { y: { beginAtZero: true, max: 100 } } }} />
            </div>

            {/* Таблица студентов */}
            <div className="mt-4">
               <ZeroClassesTable students={studentsWithZeroClasses} />
            </div>
         </div>
      </div>
   );
};

export default Dashboard;
