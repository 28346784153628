import './index.css';

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Dashboard from './pages/Dashboard';
import Users from './pages/Users';
import Settings from './pages/FeedbackPage';
import Lessons from './pages/Lessons';
import Lesson from './pages/Lesson';
import CreateLesson from './pages/CreateLesson';
import Login from './pages/Login';
import UserDetails from './pages/UserDetails';
import LessonDetails from './pages/LessonDetails';
import FeedbackPage from './pages/FeedbackPage';

const App = () => {
   return (
      <Router>
         <div className="flex">
            <Sidebar />
            <div className="flex-1 p-10 text-white bg-[#F4F7FE]">
               <Routes>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/lessons" element={<Lessons />} />
                  <Route path="/lesson/:id" element={<Lesson />} />
                  <Route path="/lesson-details/:id" element={<LessonDetails />} />
                  <Route path="/lesson/create/:id" element={<CreateLesson />} />
                  <Route path="/users" element={<Users />} />
                  <Route path="/user-details/:id" element={<UserDetails />} />
                  <Route path="/feedbacks" element={<FeedbackPage />} />
               </Routes>
            </div>
         </div>
      </Router>
   );
};

export default App;
