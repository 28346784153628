import React from 'react';

const WeekScheduleCard = (props) => {
   const handleDeleteLesson = (lessonIndex) => {
      const updatedLessons = props.lessons.filter(
         (_, index) => index !== lessonIndex,
      );
      props.onDelete(props.weekDay, updatedLessons);
   };

   return (
      <div>
         <p className="text-[#707EAE] text-xl mb-1">{props.weekDay}</p>

         <div className="bg-white w-[230px] h-fit rounded-[20px] mt-2 p-4">
            <table className="w-full text-left">
               <thead>
                  <tr>
                     <th className="text-[#707EAE] text-sm">Coach</th>
                     <th className="text-[#707EAE] text-sm">Time</th>
                     <th className="text-[#707EAE] text-sm">Actions</th>
                  </tr>
               </thead>

               {props.lessons.length < 1
                  ? ''
                  : props.lessons.map((item, index) => {
                       return (
                          <tbody key={index}>
                             <tr>
                                <td className="text-[#1B2559] text-base">
                                   {item.teacher_name}
                                </td>
                                <td className="text-[#1B2559] text-base">
                                   {item.time}
                                </td>
                                <td>
                                   <button
                                      onClick={() => handleDeleteLesson(index)}
                                      className="text-red-500"
                                   >
                                      Delete
                                   </button>
                                </td>
                             </tr>
                          </tbody>
                       );
                    })}
            </table>
            {props.lessons.length < 1 ? (
               <div className="flex w-full">
                  <div className="flex-col mx-auto align-middle items-center">
                     <span className="flex pt-8 text-[#504f50] text-[100px]  items-center">
                        🗒
                     </span>
                     <span className="flex pb-8 text-[#504f50] font-bold  text-[24px] items-center">
                        No Data
                     </span>
                  </div>
               </div>
            ) : (
               ''
            )}
         </div>
      </div>
   );
};

export default WeekScheduleCard;
