import React, { useEffect, useState } from "react";
import axiosInstance from "../axiosConfig";
import { useNavigate } from "react-router-dom";

const TopLessons = () => {
   const [feedbackRating, setFeedbackRating] = useState([]);
   const [loading, setLoading] = useState(true);

   const navigate = useNavigate(); // Используем useNavigate для навигации

   useEffect(() => {
      const fetchData = async () => {
         try {
            const response = await axiosInstance.get("/v1/stats/feedback-rate");

            setFeedbackRating(response.data);

            setLoading(false);
         } catch (error) {
            console.log(error);

            setLoading(false);
         }
      };

      fetchData();
   }, []);

   if (loading) {
      return;
   }

   console.log(feedbackRating);

   return (
      <div className="bg-white p-6 rounded-[20px]  w-[420px]">
         <div className="flex justify-between items-center mb-4">
            <h2 className="text-[20px] font-bold text-[#1B2559]">Top Sport Sections</h2>
            <button
               onClick={() => {
                  navigate("/feedbacks");
               }}
               className="text-sm text-blue-600 hover:underline"
            >
               See all
            </button>
         </div>

         <table className="w-full text-left">
            <thead>
               <tr>
                  <th className="text-[#A3AED0] text-[14px] font-medium pb-2">Sport</th>
                  <th className="text-[#A3AED0] text-[14px] font-medium pb-2">Feedbacks</th>
                  <th className="text-[#A3AED0] text-[14px] font-medium pb-2">Rating</th>
               </tr>
            </thead>
            <tbody>
               {feedbackRating.map((sport, index) => (
                  <tr key={index}>
                     <td className="py-2 flex items-center">
                        <div className="w-8 h-8 bg-gray-300 rounded-full mr-3 overflow-hidden">
                           <img
                              src={`https://storage.googleapis.com/class_type_img/${sport.class_type_id}.png`}
                              alt={sport.class_type_name}
                              className="w-full h-full object-cover"
                           />
                        </div>
                        <span className="text-[#1B2559] text-[14px] font-semibold truncate max-w-[150px]">
                           {sport.class_type_name}
                        </span>
                     </td>
                     <td className="py-2 text-[#A3AED0]">{sport.total_feedback}</td>
                     <td className="py-2 text-right">
                        <div className="w-full max-w-[100px] bg-gray-200 rounded-full h-2 ml-auto">
                           <div
                              className="bg-[#4318FF] h-2 rounded-full"
                              style={{ width: `${sport.average_rating * 10}%` }}
                           ></div>
                        </div>
                     </td>
                  </tr>
               ))}
            </tbody>
         </table>
      </div>
   );
};

export default TopLessons;
