import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../axiosConfig';
import LessonsTable from '../components/LessonsTable';

const Lesson = () => {
   const { id } = useParams();
   const [lesson, setLesson] = useState(null);
   const [isLoading, setIsLoading] = useState(true);
   const [descriptions, setDescriptions] = useState({
      en: '',
      kz: '',
      ru: '',
   });
   const [rules, setRules] = useState({
      en_rule: '',
      kz_rule: '',
      ru_rule: '',
   });

   useEffect(() => {
      const fetchData = async () => {
         try {
            const response = await axiosInstance.get(`/v1/classtype/${id}`);
            setLesson(response.data.class_type);
            setIsChecked(response.data.class_type.lfk);

            const description = response.data.class_type.description;

            // Парсим строку description вручную
            const parsedDescriptions = parseDescription(description);
            setDescriptions({
               en: parsedDescriptions.en,
               kz: parsedDescriptions.kz,
               ru: parsedDescriptions.ru,
            });

            setRules({
               en_rule: parsedDescriptions.en_rule,
               kz_rule: parsedDescriptions.kz_rule,
               ru_rule: parsedDescriptions.ru_rule,
            });

            setIsLoading(false);
         } catch (error) {
            console.error(error);
            setIsLoading(false);
         }
      };

      fetchData();
   }, [id]);

   const navigate = useNavigate();
   const [isChecked, setIsChecked] = useState(false);

   const handleToggle = () => {
      setIsChecked(!isChecked);
   };

   // Функция для сохранения данных
   const handleSave = async () => {
      // Собираем строку description вручную
      const compiledDescription = formatDescription({
         en: descriptions.en,
         kz: descriptions.kz,
         ru: descriptions.ru,
         en_rule: rules.en_rule,
         kz_rule: rules.kz_rule,
         ru_rule: rules.ru_rule,
      });

      try {
         await axiosInstance.patch(`/v1/classtype/${id}`, {
            description: compiledDescription, // Отправляем строку
            lfk: isChecked,
         });
         alert('Lesson updated successfully');
      } catch (error) {
         console.error(error);
         alert('Failed to update lesson');
      }
   };

   // Парсинг строки description вручную
   const parseDescription = (descriptionStr) => {
      const regex = /(\w+):\s*{([^}]*)}/g;
      const result = {};
      let match;
      while ((match = regex.exec(descriptionStr)) !== null) {
         const key = match[1];
         const value = match[2].trim();
         result[key] = value;
      }
      return result;
   };

   // Форматируем объект обратно в строку
   const formatDescription = (descriptions) => {
      return `
         en: {${descriptions.en}}, en_rule: {${descriptions.en_rule}};
         kz: {${descriptions.kz}}, kz_rule: {${descriptions.kz_rule}};
         ru: {${descriptions.ru}}, ru_rule: {${descriptions.ru_rule}};
      `;
   };

   const handleDescriptionChange = (lang, value) => {
      setDescriptions((prev) => ({
         ...prev,
         [lang]: value,
      }));
   };

   const handleRuleChange = (lang, value) => {
      setRules((prev) => ({
         ...prev,
         [lang]: value,
      }));
   };

   if (isLoading) {
      return <div>Loading...</div>;
   }

   return (
      <div>
         <div>
            <p className="text-[#707EAE]">Pages / Lesson / {id} </p>
            <h2 className="text-3xl font-bold text-[#2B3674]">
               {lesson.title}
            </h2>
         </div>

         <h2 className="text-2xl font-bold text-[#2B3674] mt-4">Description</h2>

         <div className="mt-2 flex">
            <textarea
               className="text-[#000] w-[450px] h-[225px] resize-none outline-none p-4 rounded-[20px] mr-8"
               value={descriptions.kz} // Отображаем текущее значение
               onChange={(e) => handleDescriptionChange('kz', e.target.value)}
            />
            <textarea
               className="text-[#000] w-[450px] h-[225px] resize-none outline-none p-4 rounded-[20px] mr-8"
               value={descriptions.en} // Отображаем текущее значение
               onChange={(e) => handleDescriptionChange('en', e.target.value)}
            />
            <textarea
               className="text-[#000] w-[450px] h-[225px] resize-none outline-none p-4 rounded-[20px] mr-8"
               value={descriptions.ru} // Отображаем текущее значение
               onChange={(e) => handleDescriptionChange('ru', e.target.value)}
            />
         </div>

         <h2 className="text-2xl font-bold text-[#2B3674] mt-4">Rules</h2>

         <div className="mt-2 flex">
            <textarea
               className="text-[#000] w-[450px] h-[225px] resize-none outline-none p-4 rounded-[20px] mr-8"
               value={rules.kz_rule} // Отображаем текущее значение
               onChange={(e) => handleRuleChange('kz_rule', e.target.value)}
            />
            <textarea
               className="text-[#000] w-[450px] h-[225px] resize-none outline-none p-4 rounded-[20px] mr-8"
               value={rules.en_rule} // Отображаем текущее значение
               onChange={(e) => handleRuleChange('en_rule', e.target.value)}
            />
            <textarea
               className="text-[#000] w-[450px] h-[225px] resize-none outline-none p-4 rounded-[20px] mr-8"
               value={rules.ru_rule} // Отображаем текущее значение
               onChange={(e) => handleRuleChange('ru_rule', e.target.value)}
            />
         </div>

         <h2 className="text-2xl font-bold text-[#2B3674] mt-4">Parameters</h2>

         <div className="mt-2 flex">
            <div className="h-full">
               <p className="text-[#707EAE] mb-1">Icon</p>
               <input
                  className="text-[#000] w-[350px] h-[48px] resize-none outline-none p-4 rounded-[16px] mr-8"
                  value={lesson.image_url || ''} // Отображаем текущее значение
               />
            </div>

            <div className="h-full">
               <p className="text-[#707EAE] mb-1">Location</p>
               <input
                  className="text-[#000] w-[350px] h-[48px] resize-none outline-none p-4 rounded-[16px] mr-8"
                  value={lesson.location || ''} // Отображаем текущее значение
               />
            </div>

            <div className="h-full">
               <p className="text-[#707EAE] mb-1">LFK</p>
               <label className="flex items-center cursor-pointer mt-3">
                  <div className="relative items-center align-middle my-auto">
                     <input
                        type="checkbox"
                        className="sr-only"
                        checked={isChecked}
                        onChange={handleToggle}
                     />
                     <div
                        className={`block w-14 h-8 rounded-full ${isChecked ? 'bg-[#11047A]' : 'bg-gray-200'}`}
                     ></div>
                     <div
                        className={`dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition ${isChecked ? 'transform translate-x-6' : ''}`}
                     ></div>
                  </div>
               </label>
            </div>

            <div className="h-full ml-8">
               <p className="mb-1">Save</p>
               <button
                  onClick={handleSave}
                  className="w-[114px] h-[45px] rounded-full my-auto bg-slate-500"
               >
                  <span className="text-white font-bold">Save</span>
               </button>
            </div>
         </div>
         <LessonsTable classType={id}/>
      </div>
   );
};

export default Lesson;
