import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Для навигации между страницами
import axiosInstance from "../axiosConfig";

const FeedbackPage = () => {
   const [feedbacks, setFeedbacks] = useState({});
   const [activeTab, setActiveTab] = useState(null); // Активный тип секции
   const [loading, setLoading] = useState(true);
   const navigate = useNavigate(); // Хук для навигации

   useEffect(() => {
      const fetchFeedbacks = async () => {
         try {
            const response = await axiosInstance.get("/v1/stats/feedbacks");
            setFeedbacks(response.data);
            setActiveTab(Object.keys(response.data)[0]); // Устанавливаем первую вкладку активной
            setLoading(false);
         } catch (error) {
            console.error("Error fetching feedbacks:", error);
         }
      };

      fetchFeedbacks();
   }, []);

   if (loading) {
      return <div className="flex justify-center items-center h-screen">Loading...</div>;
   }

   return (
      <div className="bg-whites min-h-screen p-6">
         <div className="mb-8">
            <p className="text-[#707EAE]">Pages / Lessons</p>
            <h2 className="text-3xl font-bold text-[#2B3674]">Lessons</h2>
         </div>

         <div className="flex justify-center mb-6">
            {/* Вкладки для типов секций */}
            {Object.keys(feedbacks).map((classTypeId) => (
               <button
                  key={classTypeId}
                  onClick={() => setActiveTab(classTypeId)}
                  className={`px-4 py-2 mx-2 rounded-lg text-white font-semibold ${
                     activeTab === classTypeId ? "bg-blue-600" : "bg-gray-400"
                  }`}
               >
                  {feedbacks[classTypeId][0]?.class_type || "Unknown"}
               </button>
            ))}
         </div>

         <div className="max-w-5xl mx-auto">
            {/* Отображение фидбеков для активной вкладки */}
            {feedbacks[activeTab]?.map((feedback) => (
               <div key={feedback.id} className="bg-white p-6 mb-4 rounded-lg shadow-md">
                  <div className="flex justify-between items-center mb-2">
                     {/* Переход на страницу урока при клике */}
                     <h2
                        className="text-2xl font-bold text-blue-600 cursor-pointer"
                        onClick={() => navigate(`/lesson-details/${feedback.lesson.id}`)}
                     >
                        {feedback.lesson.title}
                     </h2>
                     <span className="text-sm text-gray-600">{new Date(feedback.created_at).toLocaleDateString()}</span>
                  </div>
                  <p className="text-lg text-gray-700 mb-4">{feedback.message}</p>
                  <div className="flex justify-between items-center">
                     <div
                        className="flex items-center space-x-3 cursor-pointer"
                        onClick={() => navigate(`/user-details/${feedback.author.id}`)}
                     >
                        {/* Переход на страницу пользователя при клике */}
                        <img
                           src={`https://api.dicebear.com/5.x/identicon/svg?seed=${feedback.author.id}`}
                           alt="Author Avatar"
                           className="w-10 h-10 rounded-full"
                        />
                        <div>
                           <p className="text-sm font-semibold text-gray-800">
                              {feedback.author.name} {feedback.author.surname}
                           </p>
                           <p className="text-sm text-gray-500">{feedback.author.email}</p>
                        </div>
                     </div>
                     <span
                        className={`text-lg font-semibold ${
                           feedback.rating >= 7 ? "text-green-500" : feedback.rating >= 4 ? "text-yellow-500" : "text-red-500"
                        }`}
                     >
                        {feedback.rating}/10
                     </span>
                  </div>
               </div>
            ))}
         </div>
      </div>
   );
};

export default FeedbackPage;
