import React, { useEffect, useState } from 'react';
import axiosInstance from '../axiosConfig';

const LogViewer = ({ url, title }) => {
    const [logs, setLogs] = useState([]); // Состояние для хранения логов
    const [loading, setLoading] = useState(true); // Состояние загрузки
    const [error, setError] = useState(''); // Состояние для ошибок
    const [showLogs, setShowLogs] = useState(false); // Состояние для показа/скрытия логов
    const [startDate, setStartDate] = useState(''); // Начальная дата фильтрации
    const [endDate, setEndDate] = useState(''); // Конечная дата фильтрации

    // Функция для форматирования даты и времени с точностью до секунд
    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        return new Intl.DateTimeFormat('ru-RU', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        }).format(date);
    };

    // Функция для загрузки логов с сервера
    const fetchLogs = async () => {
        try {
            const response = await axiosInstance.get(url); // Запрос к API для получения логов
            setLogs(response.data.auth_logs ?? []); // Обновляем состояние логов
            setLoading(false); // Отключаем состояние загрузки
        } catch (error) {
            setError('Failed to fetch logs.'); // Обрабатываем ошибки
            setLoading(false); // Отключаем состояние загрузки при ошибке
        }
    };

    // Функция для группировки логов по времени (до секунд)
    const groupLogsByTime = (logs) => {
        const groupedLogs = {};
        logs.forEach((log) => {
            const timeKey = formatDate(log.time); // Используем отформатированную дату как ключ
            if (!groupedLogs[timeKey]) {
                groupedLogs[timeKey] = [];
            }
            groupedLogs[timeKey].push(log);
        });
        return groupedLogs;
    };

    // Фильтрация логов по времени
    const filterLogsByDate = () => {
        if (!startDate && !endDate) return logs; // Если фильтры не заданы, возвращаем все логи

        const filteredLogs = logs.filter((log) => {
            const logDate = new Date(log.time);
            const start = startDate ? new Date(startDate) : null;
            const end = endDate ? new Date(endDate) : null;

            if (start && end) {
                return logDate >= start && logDate <= end;
            } else if (start) {
                return logDate >= start;
            } else if (end) {
                return logDate <= end;
            }
            return true;
        });

        return filteredLogs;
    };

    // Загружаем логи при монтировании компонента
    useEffect(() => {
        fetchLogs();
    }, []);

    const toggleLogsVisibility = () => {
        setShowLogs(!showLogs); // Меняем состояние показа логов и фильтра
    };

    if (loading) {
        return <div>Loading logs...</div>; // Показываем индикатор загрузки
    }

    if (error) {
        return <div className="text-red-500">{error}</div>; // Показываем ошибку
    }

    // Фильтруем и группируем логи по времени
    const filteredLogs = filterLogsByDate();
    const groupedLogs = groupLogsByTime(filteredLogs);

    return (
        <div className="mt-8">
            <h3 className="text-2xl font-bold text-[#2B3674]">
                {title} Logs
                <button 
                    onClick={toggleLogsVisibility} 
                    className="ml-4 text-blue-500 underline"
                >
                    {showLogs ? 'Hide Logs' : 'Show Logs'}
                </button>
            </h3>

            {showLogs && (
                <>
                    {/* Поля для фильтрации по дате */}
                    <div className="my-4">
                        <label className="mr-2 text-[#2B3674]">Start Date:</label>
                        <input
                            type="datetime-local"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            className="border px-2 py-1 mr-4 text-[#2B3674]"
                        />
                        <label className="mr-2 text-[#2B3674]">End Date:</label>
                        <input
                            type="datetime-local"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            className="border px-2 py-1 text-[#2B3674]"
                        />
                    </div>

                    {/* Таблица с логами */}
                    <div className="mt-4">
                        {Object.keys(groupedLogs).map((time) => (
                            <div key={time} className="mb-6">
                                <h4 className="font-semibold text-lg mb-2 text-[#2B3674]">{time}</h4>
                                <table className="min-w-full bg-white">
                                    <thead className="bg-gray-800 text-white">
                                        <tr>
                                            <th className="text-left py-3 px-4 uppercase font-semibold text-sm">ID</th>
                                            <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Level</th>
                                            <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Message</th>
                                            <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Properties</th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-gray-700">
                                        {groupedLogs[time].map((log) => (
                                            <tr key={log._id} className="bg-gray-100">
                                                <td className="text-left py-3 px-4">{log._id}</td>
                                                <td className="text-left py-3 px-4">{log.level}</td>
                                                <td className="text-left py-3 px-4">{log.message}</td>
                                                <td className="text-left py-3 px-4">
                                                    {log.properties && (
                                                        <div>
                                                            {Object.entries(log.properties).map(([key, value]) => (
                                                                <p key={key}><strong>{key}:</strong> {value.toString()}</p>
                                                            ))}
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

export default LogViewer;
