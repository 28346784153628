import React from "react";
import { NavLink } from "react-router-dom";

const Sidebar = () => {
   return (
      <div className="h-screen w-[290px] bg-white text-white flex flex-col">
         <div className="w-full h-[100px]  flex">
            <div className="m-auto">
               <span className="text-[26px] font-bold text-[#2B3674] font-poppins">NARXOZ</span>{" "}
               <span className="text-[26px] text-[#2B3674] font-poppins">SPORT</span>
            </div>
         </div>
         <nav className="mt-10 w-full flex">
            <ul className="ml-6 w-full">
               <li className="mb-3 w-full">
                  <NavLink
                     to="/"
                     className={({ isActive }) =>
                        isActive
                           ? "flex items-center p-2 rounded text-[#2B3674] font-bold w-full border-r-4 border-[#4318FF] "
                           : "flex items-center p-2 hover:bg-gray-700 rounded text-[#A3AED0] font-bold transition-all duration-300 ease"
                     }
                     exact
                  >
                     {({ isActive }) => (
                        <div className="flex align-middle items-center">
                           <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                 d="M8.0001 16V11H12.0001V16C12.0001 16.55 12.4501 17 13.0001 17H16.0001C16.5501 17 17.0001 16.55 17.0001 16V8.99997H18.7001C19.1601 8.99997 19.3801 8.42997 19.0301 8.12997L10.6701 0.599971C10.2901 0.259971 9.7101 0.259971 9.3301 0.599971L0.970098 8.12997C0.630098 8.42997 0.840098 8.99997 1.3001 8.99997H3.0001V16C3.0001 16.55 3.4501 17 4.0001 17H7.0001C7.5501 17 8.0001 16.55 8.0001 16Z"
                                 fill={isActive ? "#4318FF" : "#A3AED0"}
                              />
                           </svg>
                           <span className="text-lg ml-2">Dashboard</span>
                        </div>
                     )}
                  </NavLink>
               </li>

               <li className="mb-3 w-full">
                  <NavLink
                     to="/lessons"
                     className={({ isActive }) =>
                        isActive
                           ? "flex items-center p-2 rounded text-[#2B3674] font-bold w-full border-r-4 border-[#4318FF]"
                           : "flex items-center p-2 hover:bg-gray-700 rounded text-[#A3AED0] font-bold transition-all duration-300 ease"
                     }
                     exact
                  >
                     {({ isActive }) => (
                        <div className="flex align-middle items-center">
                           <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                 d="M8.0001 16V11H12.0001V16C12.0001 16.55 12.4501 17 13.0001 17H16.0001C16.5501 17 17.0001 16.55 17.0001 16V8.99997H18.7001C19.1601 8.99997 19.3801 8.42997 19.0301 8.12997L10.6701 0.599971C10.2901 0.259971 9.7101 0.259971 9.3301 0.599971L0.970098 8.12997C0.630098 8.42997 0.840098 8.99997 1.3001 8.99997H3.0001V16C3.0001 16.55 3.4501 17 4.0001 17H7.0001C7.5501 17 8.0001 16.55 8.0001 16Z"
                                 fill={isActive ? "#4318FF" : "#A3AED0"}
                              />
                           </svg>
                           <span className="text-lg ml-2">Lessons</span>
                        </div>
                     )}
                  </NavLink>
               </li>
               <li className="mb-3 w-full">
                  <NavLink
                     to="/users"
                     className={({ isActive }) =>
                        isActive
                           ? "flex items-center p-2 rounded text-[#2B3674] font-bold w-full border-r-4 border-[#4318FF]"
                           : "flex items-center p-2 hover:bg-gray-700 rounded text-[#A3AED0] font-bold transition-all duration-300 ease"
                     }
                  >
                     {({ isActive }) => (
                        <div className="flex align-middle items-center">
                           <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                 d="M8.0001 16V11H12.0001V16C12.0001 16.55 12.4501 17 13.0001 17H16.0001C16.5501 17 17.0001 16.55 17.0001 16V8.99997H18.7001C19.1601 8.99997 19.3801 8.42997 19.0301 8.12997L10.6701 0.599971C10.2901 0.259971 9.7101 0.259971 9.3301 0.599971L0.970098 8.12997C0.630098 8.42997 0.840098 8.99997 1.3001 8.99997H3.0001V16C3.0001 16.55 3.4501 17 4.0001 17H7.0001C7.5501 17 8.0001 16.55 8.0001 16Z"
                                 fill={isActive ? "#4318FF" : "#A3AED0"}
                              />
                           </svg>
                           <span className="text-lg ml-2">Students</span>
                        </div>
                     )}
                  </NavLink>
               </li>
               <li className="mb-3">
                  <NavLink
                     to="/feedbacks"
                     className={({ isActive }) =>
                        isActive
                           ? "flex items-center p-2 rounded text-[#2B3674] font-bold w-full border-r-4 border-[#4318FF]"
                           : "flex items-center p-2 hover:bg-gray-700 rounded text-[#A3AED0] font-bold transition-all duration-300 ease"
                     }
                  >
                     {({ isActive }) => (
                        <div className="flex align-middle items-center">
                           <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                 d="M8.0001 16V11H12.0001V16C12.0001 16.55 12.4501 17 13.0001 17H16.0001C16.5501 17 17.0001 16.55 17.0001 16V8.99997H18.7001C19.1601 8.99997 19.3801 8.42997 19.0301 8.12997L10.6701 0.599971C10.2901 0.259971 9.7101 0.259971 9.3301 0.599971L0.970098 8.12997C0.630098 8.42997 0.840098 8.99997 1.3001 8.99997H3.0001V16C3.0001 16.55 3.4501 17 4.0001 17H7.0001C7.5501 17 8.0001 16.55 8.0001 16Z"
                                 fill={isActive ? "#4318FF" : "#A3AED0"}
                              />
                           </svg>
                           <span className="text-lg ml-2">Feedbacks</span>
                        </div>
                     )}
                  </NavLink>
               </li>
            </ul>
         </nav>
      </div>
   );
};

export default Sidebar;
