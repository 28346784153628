import React from 'react';
import { useNavigate } from 'react-router-dom';

function LessonCard(props) {
   const navigate = useNavigate();

   const handleButtonClick = () => {
      navigate(`/lesson/${props.id}`);
   };

   return (
      <div className="w-[350px] h-[370px] bg-white rounded-[20px] flex-col space-y-4 mb-6 cursor-pointer transition-all 300 ease-in hover:scale-105">
         {/* Image */}
         <div className="w-full h-[205px] bg-slate-600 rounded-[18px] mx-auto overflow-hidden">
            <img
               className="w-full h-full object-contain"
               src={props.cardImg}
               alt="img"
            />
         </div>

         <div className="flex mx-6 justify-between">
            <div>
               <span className="flex font-bold text-[18px] text-[#1B2559]">
                  {props.title}
               </span>

               <span className="flex font-regular text-[14px] text-[#A3AED0]">
                  {props.lessonCount} lessons
               </span>
            </div>

            <div className="bg-[#A3AED0] rounded-full p-2">
               <img
                  className="w-[32px] h-[32px]"
                  src={props.imgUrl}
                  alt="sport-icon"
               />
            </div>
         </div>

         <div className="mx-6 flex justify-between pt-6 items-center">
            <span className="text-[#4318FF] font-bold text-[14px]">
               ID: {props.id}
            </span>

            <button
               onClick={handleButtonClick}
               className="bg-[#11047A] w-[108px] h-[34px] rounded-[70px] hover:opacity-85 transition-all 300s ease-in-out"
            >
               <span className="font-bold text-[14px] text-[#fff]">Manage</span>
            </button>
         </div>
      </div>
   );
}

export default LessonCard;
