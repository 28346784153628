import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../axiosConfig';

const LessonDetails = () => {
    const { id } = useParams(); // Получаем id урока из параметров URL
    const [lesson, setLesson] = useState(null); // Состояние для данных урока
    const [students, setStudents] = useState([]); // Состояние для списка студентов
    const [loading, setLoading] = useState(true); // Состояние загрузки
    const [error, setError] = useState(''); // Состояние для ошибок

    // Функция для форматирования даты и времени
    const formatDate = (dateStr) => {
        if (!dateStr) return 'Invalid date'; // Проверяем, что дата существует
        const date = new Date(dateStr);
        if (isNaN(date)) return 'Invalid date'; // Проверяем корректность даты
        return new Intl.DateTimeFormat('ru-RU', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        }).format(date);
    };

    // Функция для получения данных урока и зарегистрированных студентов
    const fetchLessonDetails = async () => {
        try {
            const lessonResponse = await axiosInstance.get(`/v1/lesson/details/admin?id=${id}`);
            setLesson(lessonResponse.data.lesson ?? []); // Обновляем данные урока
            setStudents(lessonResponse.data.students ?? []); // Обновляем данные студентов
            setLoading(false); // Отключаем состояние загрузки
        } catch (error) {
            setError('Failed to fetch lesson details or students.');
            setLoading(false); // Отключаем состояние загрузки при ошибке
        }
    };

    // Обновление статуса посещаемости
    const updateAttendance = async (studentId, attended) => {
        try {
            await axiosInstance.patch(`/v1/students/${studentId}/attendance`, { attended });
            setStudents(students.map(student => 
                student.student_id === studentId ? { ...student, attended } : student
            ));
        } catch (error) {
            setError('Failed to update attendance.');
        }
    };

    // Загружаем данные при монтировании компонента
    useEffect(() => {
        fetchLessonDetails();
    }, [id]);

    if (loading) {
        return <div>Loading...</div>; // Индикатор загрузки
    }

    if (error) {
        return <div className="text-red-500">{error}</div>; // Сообщение об ошибке
    }

    return (
        <div>
            <h2 className="text-3xl font-bold text-[#2B3674]">Lesson Details</h2>

            {/* Отображаем информацию о уроке */}
            {lesson && (
                <div className="bg-[#1F2937] p-6 rounded shadow-md mt-6 text-[#F9FAFB]">
                    <img
                        src={lesson.image_url || 'default_image.jpg'} // Используем изображение урока или дефолтную картинку
                        alt={lesson.title}
                        className="w-48 h-48 rounded mb-4 border-4 border-[#4B5563]"
                    />
                    <p><strong>Title:</strong> {lesson.title}</p>
                    <p><strong>Coach:</strong> {lesson.teacher_name}</p>
                    <p><strong>Location:</strong> {lesson.location}</p>
                    <p><strong>Start Time:</strong> {formatDate(lesson.start_date)}</p> {/* Исправлено поле */}
                </div>
            )}

            {/* Таблица зарегистрированных студентов */}
            {students.length > 0 ? (
                <div className="mt-8">
                    <h3 className="text-2xl font-bold text-[#2B3674]">Registered Students</h3>
                    <table className="min-w-full bg-white">
                        <thead className="bg-gray-800 text-white">
                            <tr>
                                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">ID</th>
                                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Email</th>
                                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">First Name</th>
                                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Last Name</th>
                                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Booked At</th>
                                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Attended</th>
                                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Action</th>
                            </tr>
                        </thead>
                        <tbody className="text-gray-700">
                            {students.map((student) => (
                                <tr key={student.student_id} className="bg-gray-100">
                                    <td className="text-left py-3 px-4">{student.student_id}</td>
                                    <td className="text-left py-3 px-4">{student.email}</td>
                                    <td className="text-left py-3 px-4">{student.first_name}</td>
                                    <td className="text-left py-3 px-4">{student.last_name}</td>
                                    <td className="text-left py-3 px-4">{formatDate(student.booked_at)}</td>
                                    <td className="text-left py-3 px-4">{student.attended ? 'Yes' : 'No'}</td>
                                    <td className="text-left py-3 px-4">
                                        <button
                                            className={`px-4 py-2 rounded ${
                                                student.attended ? 'bg-red-500' : 'bg-green-500'
                                            } text-white`}
                                            onClick={() => updateAttendance(student.student_id, !student.attended)}
                                        >
                                            {student.attended ? 'Mark as Not Attended' : 'Mark as Attended'}
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <p className="mt-4 text-[#2B3674]">No students registered for this lesson.</p>
            )}
        </div>
    );
};

export default LessonDetails;
