import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../axiosConfig';

const LessonsTable = ({ classType }) => {
   const [isLoading, setIsLoading] = useState(true);
   const [classesData, setClassesData] = useState([]);
   const [filter, setFilter] = useState({
      teacherName: '',
      teacherSurname: '',
      startDate: '',
      availableOnly: false,
   });
   const navigate = useNavigate();

   useEffect(() => {
      const fetchClasses = async () => {
         try {
            const response = await axiosInstance.get(`/v1/admin/lessons?class_type=${classType}`);
            setClassesData(response.data.lessons);
            setIsLoading(false);
         } catch (error) {
            console.error('Error fetching classes:', error);
            setIsLoading(false);
         }
      };

      fetchClasses();
   }, [classType]);

   const handleFilterChange = (e) => {
      const { name, value, type, checked } = e.target;
      setFilter((prev) => ({
         ...prev,
         [name]: type === 'checkbox' ? checked : value,
      }));
   };

   const handleDeleteLesson = async (lessonId) => {
      try {
         await axiosInstance.delete(`/v1/lessons/${lessonId}`);
         alert('Lesson deleted successfully');
         setClassesData(classesData.filter((lesson) => lesson.lesson_id !== lessonId));
      } catch (error) {
         console.error('Error deleting lesson:', error);
         alert('Failed to delete lesson');
      }
   };

   if (isLoading) {
      return null;
   }

   const filteredClasses = classesData.filter((classItem) => {
      const matchesTeacherName = classItem.teacher_name.toLowerCase().includes(filter.teacherName.toLowerCase());
      const matchesTeacherSurname = classItem.teacher_surname.toLowerCase().includes(filter.teacherSurname.toLowerCase());
      const matchesStartDate = filter.startDate ? classItem.start_date.startsWith(filter.startDate) : true;
      const matchesAvailableSlots = filter.availableOnly ? classItem.available_slots > 0 : true;

      return matchesTeacherName && matchesTeacherSurname && matchesStartDate && matchesAvailableSlots;
   });

   return (
      <div className="py-4">
         <h2 className="text-2xl font-bold text-[#2B3674] mb-4">
            Classes Schedule
         </h2>

         <div className="flex gap-4 mb-4">
            <input
               type="text"
               name="teacherName"
               value={filter.teacherName}
               onChange={handleFilterChange}
               placeholder="Teacher Name"
               className="w-[200px] h-[40px] p-2 border rounded text-[#2B3674]"
            />
            <input
               type="text"
               name="teacherSurname"
               value={filter.teacherSurname}
               onChange={handleFilterChange}
               placeholder="Teacher Surname"
               className="w-[200px] h-[40px] p-2 border rounded text-[#2B3674]"
            />
            <input
               type="date"
               name="startDate"
               value={filter.startDate}
               onChange={handleFilterChange}
               className="w-[200px] h-[40px] p-2 border rounded text-[#bababa]"
            />
            <label className="flex items-center gap-2 text-[#bababa]">
               <input
                  type="checkbox"
                  name="availableOnly"
                  className='text-[#bababa]'
                  checked={filter.availableOnly}
                  onChange={handleFilterChange}
               />
               Show lessons with available slots
            </label>
         </div>

         <table className="min-w-full bg-white rounded-[20px] border-gray-200 text-black">
            <thead>
               <tr>
                  <th className="py-2 px-4 border-b">ID</th>
                  <th className="py-2 px-4 border-b text-left">Title</th>
                  <th className="py-2 px-4 border-b text-left">Teacher</th>
                  <th className="py-2 px-4 border-b text-left">Location</th>
                  <th className="py-2 px-4 border-b text-left">Start Date & Time</th>
                  <th className="py-2 px-4 border-b">Max Students</th>
                  <th className="py-2 px-4 border-b">Available Slots</th>
                  <th className="py-2 px-4 border-b">Actions</th>
               </tr>
            </thead>
            <tbody>
               {filteredClasses.map((classItem) => (
                  <tr
                     className="text-black cursor-pointer transition duration-300 ease-in-out transform hover:bg-gray-100 hover:scale-105"
                     key={classItem.lesson_id}
                     onClick={() => navigate(`/lesson-details/${classItem.lesson_id}`)}
                  >
                     <td className="py-2 px-4 border-b text-center">
                        {classItem.lesson_id}
                     </td>
                     <td className="py-2 px-4 border-b text-left">
                        {classItem.lesson_title}
                     </td>
                     <td
                        className="py-2 px-4 border-b text-left text-blue-500 underline cursor-pointer"
                        onClick={(e) => {
                           e.stopPropagation();
                           navigate(`/user-details/${classItem.teacher_id}`);
                        }}
                     >
                        {classItem.teacher_name} {classItem.teacher_surname}
                     </td>
                     <td className="py-2 px-4 border-b text-left">
                        {classItem.location}
                     </td>
                     <td className="py-2 px-4 border-b text-left">
                        {new Date(classItem.start_date).toLocaleString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' })}
                     </td>
                     <td className="py-2 px-4 border-b text-center">
                        {classItem.maximum_students}
                     </td>
                     <td className="py-2 px-4 border-b text-center">
                        {classItem.available_slots}
                     </td>
                     <td className="py-2 px-4 border-b text-center">
                        <button
                           onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteLesson(classItem.lesson_id);
                           }}
                           className="text-red-500 underline"
                        >
                           Delete
                        </button>
                     </td>
                  </tr>
               ))}
            </tbody>
         </table>
      </div>
   );
};

export default LessonsTable;