import axios from 'axios';

const prod = 'https://narxoz-sporthub.online'
const local = 'http://localhost:8080'

const axiosInstance = axios.create({
   baseURL: prod,
});

axiosInstance.interceptors.request.use(
   (config) => {
      const token = localStorage.getItem('token');
      if (token) {
         config.headers.Authorization = `Bearer ${token}`;   
      }
      return config;
   },
   (error) => {
      return Promise.reject(error);
   },
);

axiosInstance.interceptors.response.use(
   (response) => {
      return response;
   },
   (error) => {
      if (error.response && error.response.status === 401) {
         window.location.href = '/login';  // Перенаправление на логин
      }
      return Promise.reject(error);
   },
);

export default axiosInstance;
