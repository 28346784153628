// src/pages/Login.js
import React, { useState } from "react";
import axios from "axios";

const Login = () => {
   const [email, setEmail] = useState("");
   const [password, setPassword] = useState("");
   const [error, setError] = useState("");

   const handleSubmit = async (e) => {
      e.preventDefault();
      try {
         const response = await axios.post("https://narxoz-sporthub.online/v1/tokens/login", {
            email,
            password,
         });

         console.log(response);

         if (response.data.response.user.role != "admin") {
            setError("Invalid credentials");
            return;
         }

         //   Сохраняем токен в localStorage
         localStorage.setItem("token", response.data.response.tokens.access_token);

         window.location.href = "/";
      } catch (err) {
         setError("Invalid credentials");
      }
   };

   return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
         <form onSubmit={handleSubmit} className="bg-white p-6 rounded shadow-md w-80">
            <h2 className="text-2xl mb-4">Login</h2>
            {error && <p className="text-red-500">{error}</p>}
            <input
               type="email"
               placeholder="Email"
               value={email}
               onChange={(e) => setEmail(e.target.value)}
               className="mb-4 p-2 w-full border text-[#000] border-gray-300 rounded"
               required
            />
            <input
               type="password"
               placeholder="Password"
               value={password}
               onChange={(e) => setPassword(e.target.value)}
               className="mb-4 p-2 w-full border border-gray-300 rounded text-[#000]"
               required
            />
            <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded">
               Login
            </button>
         </form>
      </div>
   );
};

export default Login;
