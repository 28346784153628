import React, { useState } from "react";
import axiosInstance from "../axiosConfig";

const CreateUserModal = ({ onClose, onSave }) => {
   const [formData, setFormData] = useState({
      name: "",
      surname: "",
      email: "",
      password: "",
      role: "student",
      major: " ",
      year: 1,
   });

   const handleChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
   };

   const handleSubmit = async (e) => {
      e.preventDefault();
      try {
         await axiosInstance.post("/v1/users", {
            ...formData,
            year: parseInt(formData.year, 10),
         });
         onSave(); // Обновляем список пользователей
         onClose(); // Закрываем модалку
      } catch (error) {
         alert("Failed to create user.");
      }
   };

   return (
      <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
         <div className="bg-white p-6 rounded shadow-md w-1/3">
            <h3 className="text-2xl font-bold mb-4">Create User</h3>
            <form onSubmit={handleSubmit}>
               <div className="mb-4">
                  <label className="block text-gray-700">Name</label>
                  <input
                     type="text"
                     name="name"
                     value={formData.name}
                     onChange={handleChange}
                     className="w-full px-4 py-2 border rounded text-gray-700"
                     required
                  />
               </div>
               <div className="mb-4">
                  <label className="block text-gray-700">Surname</label>
                  <input
                     type="text"
                     name="surname"
                     value={formData.surname}
                     onChange={handleChange}
                     className="w-full px-4 py-2 border rounded text-gray-700"
                     required
                  />
               </div>
               <div className="mb-4">
                  <label className="block text-gray-700">Email</label>
                  <input
                     type="email"
                     name="email"
                     value={formData.email}
                     onChange={handleChange}
                     className="w-full px-4 py-2 border rounded text-gray-700"
                     required
                  />
               </div>
               <div className="mb-4">
                  <label className="block text-gray-700">Password</label>
                  <input
                     type="password"
                     name="password"
                     value={formData.password}
                     onChange={handleChange}
                     className="w-full px-4 py-2 border rounded text-gray-700"
                     required
                  />
               </div>
               <div className="mb-4">
                  <label className="block text-gray-700">Role</label>
                  <select
                     name="role"
                     value={formData.role}
                     onChange={handleChange}
                     className="w-full px-4 py-2 border rounded text-gray-700"
                  >
                     <option value="student">Student</option>
                     <option value="teacher">Teacher</option>
                     <option value="admin">Admin</option>
                  </select>
               </div>
               <div className="mb-4">
                  <label className="block text-gray-700">Major</label>
                  <input
                     type="text"
                     name="major"
                     value={formData.major}
                     onChange={handleChange}
                     className="w-full px-4 py-2 border rounded text-gray-700"
                  />
               </div>
               <div className="mb-4">
                  <label className="block text-gray-700">Year</label>
                  <input
                     type="number"
                     name="year"
                     value={formData.year}
                     onChange={handleChange}
                     className="w-full px-4 py-2 border rounded text-gray-700"
                  />
               </div>
               <div className="flex justify-end">
                  <button type="button" className="bg-gray-500 text-white px-4 py-2 rounded mr-4" onClick={onClose}>
                     Cancel
                  </button>
                  <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
                     Create
                  </button>
               </div>
            </form>
         </div>
      </div>
   );
};

export default CreateUserModal;
