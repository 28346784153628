import { useParams } from 'react-router-dom';
import WeekScheduleCard from '../components/WeekScheduleCard';
import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosConfig';

const CreateLesson = () => {
   const { id } = useParams();

   const [classTypeInfo, setClassTypeInfo] = useState(null);

   const [teachers, setTeachers] = useState([]);
   const [isLoading, setIsLoading] = useState(true);
   const [location, setLocation] = useState('')

   useEffect(() => {
      const fetchData = async () => {
         const response = await axiosInstance.get('/v1/teachers');
         console.log(response);
         
         const classTypeResp = await axiosInstance.get(`/v1/classtype/${id}`);
         setClassTypeInfo(classTypeResp.data.class_type);
         setTeachers(response.data.teachers ?? []);
         setIsLoading(false);
      };

      fetchData();
   }, []);

   const initialWeekInfo = [
      {
         weekDay: 'Monday',
         lessons: [],
      },
      {
         weekDay: 'Tuesday',
         lessons: [],
      },
      {
         weekDay: 'Wednesday',
         lessons: [],
      },
      {
         weekDay: 'Thursday',
         lessons: [],
      },
      {
         weekDay: 'Friday',
         lessons: [],
      },
      {
         weekDay: 'Saturday',
         lessons: [],
      },
      {
         weekDay: 'Sunday',
         lessons: [],
      },
   ];

   const [weekInfo, setWeekInfo] = useState(() => {
      const savedWeekInfo = localStorage.getItem(`weekInfo_${id}`);
      try {
         return savedWeekInfo ? JSON.parse(savedWeekInfo) : initialWeekInfo;
      } catch (error) {
         console.error('Failed to parse weekInfo from localStorage', error);
         return initialWeekInfo;
      }
   });

   const [selectedCoach, setSelectedCoach] = useState('');
   const [selectedDay, setSelectedDay] = useState('');
   const [startTime, setStartTime] = useState('');
   const [maxStudents, setMaxStudents] = useState(0)

   const handleSelectCoach = (event) => {
      setSelectedCoach(event.target.value);
   };

   const handleSelectDay = (event) => {
      setSelectedDay(event.target.value);
   };

   const handleStartTimeChange = (event) => {
      setStartTime(event.target.value);
   };

   const [startDate, setStartDate] = useState(new Date()); // По умолчанию — сегодняшняя дата

   const addLesson = () => {
   if (!selectedCoach || !selectedDay || !startTime) {
      alert('Please fill all the fields before adding a lesson.');
      return;
   }

   const selectedTeacher = teachers.find(
      (teacher) => teacher.id === parseInt(selectedCoach),
   );

   // Функция для получения ближайшего дня недели, начиная с заданной стартовой даты
   const getNextDateOfWeekday = (weekday, startDate) => {
      const startDay = startDate.getDay(); // День недели для стартовой даты (0 - воскресенье, 1 - понедельник и т.д.)
      const diff = (weekday - startDay + 7) % 7; // Разница до следующего нужного дня недели
      return new Date(startDate.setDate(startDate.getDate() + diff)); // Возвращаем нужную дату
   };

   // Маппинг дней недели на числа
   const dateMap = {
      Monday: getNextDateOfWeekday(1, new Date(startDate)),
      Tuesday: getNextDateOfWeekday(2, new Date(startDate)),
      Wednesday: getNextDateOfWeekday(3, new Date(startDate)),
      Thursday: getNextDateOfWeekday(4, new Date(startDate)),
      Friday: getNextDateOfWeekday(5, new Date(startDate)),
      Saturday: getNextDateOfWeekday(6, new Date(startDate)),
      Sunday: getNextDateOfWeekday(0, new Date(startDate)),
   };

   const selectedDate = dateMap[selectedDay];
   const datePart = selectedDate.getDate().toString().padStart(2, '0');
   const monthPart = (selectedDate.getMonth() + 1).toString().padStart(2, '0');
   const yearPart = selectedDate.getFullYear();

   // Форматируем start_date в нужный формат "yyyy-mm-dd-hh-mm-ss"
   const formattedStartDate = `${yearPart}-${monthPart}-${datePart}-${startTime.replace(':', '-')}-00`;

   const newLesson = {
      title: classTypeInfo.title,
      class_type_id: id * 1,
      teacher_id: selectedCoach,
      start_date: formattedStartDate,
      register_open_time: '2024-08-20-08-00-00',
      register_close_time: '2024-08-25-18-00-00',
      location: location,
      maximum_students: maxStudents * 1,
      teacher_name: `${selectedTeacher.name} ${selectedTeacher.surname}`,
      time: startTime,
   };

   setWeekInfo((prevWeekInfo) => {
      const updatedWeekInfo = prevWeekInfo.map((dayInfo) =>
         dayInfo.weekDay === selectedDay
            ? {
                 ...dayInfo,
                 lessons: [...dayInfo.lessons, newLesson],
              }
            : dayInfo,
      );
      localStorage.setItem(
         `weekInfo_${id}`,
         JSON.stringify(updatedWeekInfo),
      );
      return updatedWeekInfo;
   });

   // Очистка полей после добавления урока
   // setSelectedCoach('');
   // setSelectedDay('');
   // setStartTime('');
};

// Пример изменения стартовой даты через setState
// Ты можешь использовать этот код, чтобы вручную задать новую стартовую дату
// Например, через input или любой другой механизм:
const handleStartDateChange = (event) => {
   setStartDate(new Date(event.target.value)); // Принимает дату в формате yyyy-mm-dd
};


   const deleteLesson = (weekDay, updatedLessons) => {
      setWeekInfo((prevWeekInfo) => {
         const updatedWeekInfo = prevWeekInfo.map((dayInfo) =>
            dayInfo.weekDay === weekDay
               ? {
                    ...dayInfo,
                    lessons: updatedLessons,
                 }
               : dayInfo,
         );
         localStorage.setItem(
            `weekInfo_${id}`,
            JSON.stringify(updatedWeekInfo),
         );
         return updatedWeekInfo;
      });
   };

   const saveSchedule = async () => {
      // Подготовка формата weekInfo для отправки
      const formattedWeekInfo = weekInfo.reduce((acc, dayInfo) => {
         if (dayInfo.lessons.length > 0) {
            acc[dayInfo.weekDay.toLowerCase()] = dayInfo.lessons.map(
               (lesson) => ({
                  title: lesson.title,
                  class_type_id: lesson.class_type_id,
                  teacher_id: parseInt(lesson.teacher_id, 10), // Преобразование teacher_id в число
                  start_date: lesson.start_date,
                  location: lesson.location,
                  register_open_time: lesson.register_open_time,
                  register_close_time: lesson.register_close_time,
                  maximum_students: lesson.maximum_students*1  ,
               }),
            );
         }
         return acc;
      }, {});

      try {
         console.log({ lessons: formattedWeekInfo });
         // Отправка запроса на сервер
         await axiosInstance.post(`/v1/lessons/week`, {
            lessons: formattedWeekInfo,
         });

         alert('Schedule saved successfully!');
         localStorage.removeItem(`weekInfo_${id}`); // Очистка localStorage после сохранения
      } catch (error) {
         console.error('Failed to save schedule:', error.response.data);
         alert('Failed to save schedule. Please try again.');
      }
   };

   if (isLoading) {
      return null;
   }

   return (
      <div>
         <div>
            <p className="text-[#707EAE]">Pages / Lesson / Create / {id} </p>
            <h2 className="text-3xl font-bold text-[#2B3674]">
               {classTypeInfo.title}
            </h2>
         </div>

         <div className="mt-2 flex justify-between">
            {weekInfo.map((item, index) => (
               <WeekScheduleCard
                  key={index}
                  weekDay={item.weekDay}
                  lessons={item.lessons}
                  onDelete={deleteLesson}
               />
            ))}
         </div>

         <h2 className="text-2xl font-bold text-[#2B3674] mt-8">
            Lesson details
         </h2>

         <div className="mt-2 flex">
            <div className="h-full">
               <p className="text-[#707EAE] mb-1">Coach</p>
               <select
                  value={selectedCoach}
                  onChange={handleSelectCoach}
                  className="text-[#000] w-[350px] h-[48px] resize-none outline-none p-4 rounded-[16px] mr-8 bg-white"
               >
                  <option value="" disabled>
                     Choose a coach...
                  </option>
                  {teachers.map((coach) => (
                     <option key={coach.id} value={coach.id}>
                        {coach.name} {coach.surname}
                     </option>
                  ))}
               </select>
            </div>

            <div className="h-full">
               <p className="text-[#707EAE] mb-1">Day of the Week</p>
               <select
                  value={selectedDay}
                  onChange={handleSelectDay}
                  className="text-[#000] w-[350px] h-[48px] resize-none outline-none p-4 rounded-[16px] mr-8 bg-white"
               >
                  <option value="" disabled>
                     Choose a day...
                  </option>
                  <option value="Monday">Monday</option>
                  <option value="Tuesday">Tuesday</option>
                  <option value="Wednesday">Wednesday</option>
                  <option value="Thursday">Thursday</option>
                  <option value="Friday">Friday</option>
                  <option value="Saturday">Saturday</option>
                  <option value="Sunday">Sunday</option>
               </select>
            </div>

            <div className="h-full">
               <p className="text-[#707EAE] mb-1">Start Time</p>
               <input
                  type="time"
                  value={startTime}
                  onChange={handleStartTimeChange}
                  className="text-[#000] w-[150px] h-[48px] resize-none outline-none p-4 rounded-[16px] mr-8"
               />
            </div>

            <div className="h-full">
               <p className="text-[#707EAE] mb-1">Maximum Students</p>
               <input
                  type="number"
                  value={maxStudents}
                  onChange={(e)=>{setMaxStudents(e.target.value)}}
                  className="text-[#000] w-[150px] h-[48px] resize-none outline-none p-4 rounded-[16px] mr-8"
               />
            </div>

            <div className="h-full">
               <p className="text-[#707EAE] mb-1">Location</p>
               <input
                  type="text"
                  value={location}
                  onChange={(e)=>{setLocation(e.target.value)}}
                  className="text-[#000] w-[150px] h-[48px] resize-none outline-none p-4 rounded-[16px] mr-8"
               />
            </div>

            <div className="h-full ml-8">
               <p className="mb-1">&nbsp;</p>
               <button
                  onClick={addLesson}
                  className="w-[114px] h-[45px] rounded-full my-auto bg-slate-500"
               >
                  <span className="text-white font-bold">Add</span>
               </button>
            </div>
            <div className="h-full ml-8">
               <p className="mb-1">&nbsp;</p>
               <button
                  onClick={() => {
                     localStorage.removeItem(`weekInfo_${id}`);
                     setWeekInfo(initialWeekInfo); // Clear the state as well
                  }}
                  className="w-[143px] h-[45px] rounded-full my-auto bg-slate-500"
               >
                  <span className="text-white font-bold">Clear cache</span>
               </button>
            </div>
            <div className="h-full ml-8">
               <p className="mb-1">&nbsp;</p>
               <button
                  onClick={saveSchedule}
                  className="w-[114px] h-[45px] rounded-full my-auto bg-green-500"
               >
                  <span className="text-white font-bold">Save</span>
               </button>
            </div>
         </div>
      </div>
   );
};

export default CreateLesson;
