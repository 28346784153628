import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Для навигации

// Блокировка прокрутки при открытии модалки
const toggleBodyScroll = (disable) => {
   document.body.style.overflow = disable ? "hidden" : "auto";
};

const ZeroClassesTable = ({ students }) => {
   const [isModalOpen, setIsModalOpen] = useState(false);
   const navigate = useNavigate(); // Для навигации

   const handleOpenModal = () => {
      setIsModalOpen(true);
      toggleBodyScroll(true);
   };

   const handleCloseModal = () => {
      setIsModalOpen(false);
      toggleBodyScroll(false);
   };

   const handleRowClick = (id) => {
      navigate(`/user-details/${id}`); // Перенаправление на страницу пользователя
      handleCloseModal();
   };

   const handleModalClick = (e) => {
      if (e.target === e.currentTarget) {
         handleCloseModal(); // Закрытие модалки при нажатии на фон
      }
   };

   return (
      <div>
         <h3 className="text-xl font-semibold mb-4 text-gray-800">Students with Zero Classes</h3>
         <table className="w-full border border-gray-300 rounded-lg shadow-md overflow-hidden">
            <thead>
               <tr className="bg-gray-100">
                  <th className="p-3 text-left text-black">ID</th>
                  <th className="p-3 text-left text-black">Name</th>
                  <th className="p-3 text-left text-black">Surname</th>
                  <th className="p-3 text-left text-black">Email</th>
               </tr>
            </thead>
            <tbody>
               {students.slice(0, 6).map((student) => (
                  <tr
                     key={student.id}
                     className="hover:bg-gray-50 cursor-pointer bg-white"
                     onClick={() => handleRowClick(student.id)}
                  >
                     <td className="p-3 text-gray-900">{student.id}</td>
                     <td className="p-3 text-gray-900">{student.name}</td>
                     <td className="p-3 text-gray-900">{student.surname}</td>
                     <td className="p-3 text-gray-900">{student.email}</td>
                  </tr>
               ))}

               {students.length > 6 ? (
                  <tr>
                     <td className="p-3 text-gray-900">Еще {students.length - 6} студентов</td>
                     <td className="p-3 text-gray-900"></td>
                     <td className="p-3 text-gray-900"></td>
                     <td className="p-3 text-gray-900"></td>
                  </tr>
               ) : (
                  ""
               )}
            </tbody>
         </table>

         <button onClick={handleOpenModal} className="bg-blue-500 text-white py-2 px-4 rounded mt-4">
            View All Students
         </button>

         {/* Модалка с полной таблицей студентов */}
         {isModalOpen && (
            <div
               className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
               onClick={handleModalClick} // Закрытие при нажатии на фон
            >
               <div className="bg-white p-6 rounded-lg shadow-lg w-3/4 max-h-[90vh] overflow-y-auto relative">
                  <button onClick={handleCloseModal} className="absolute top-4 right-4 bg-red-500 text-white py-1 px-3 rounded">
                     Close
                  </button>

                  <h3 className="text-xl font-semibold mb-4 text-gray-800">All Students with Zero Classes</h3>
                  <table className="w-full border border-gray-300 rounded-lg shadow-md">
                     <thead>
                        <tr className="bg-gray-100">
                           <th className="p-3 text-left text-black">ID</th>
                           <th className="p-3 text-left text-black">Name</th>
                           <th className="p-3 text-left text-black">Surname</th>
                           <th className="p-3 text-left text-black">Email</th>
                        </tr>
                     </thead>
                     <tbody>
                        {students.map((student) => (
                           <tr
                              key={student.id}
                              className="hover:bg-gray-50 cursor-pointer"
                              onClick={() => handleRowClick(student.id)}
                           >
                              <td className="p-3 text-gray-900">{student.id}</td>
                              <td className="p-3 text-gray-900">{student.name}</td>
                              <td className="p-3 text-gray-900">{student.surname}</td>
                              <td className="p-3 text-gray-900">{student.email}</td>
                           </tr>
                        ))}
                     </tbody>
                  </table>
               </div>
            </div>
         )}
      </div>
   );
};

export default ZeroClassesTable;
