import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../axiosConfig';
import { UserAva } from '../constants';
import { FaTrashAlt } from 'react-icons/fa'; // Иконка для удаления
import LogViewer from '../components/LogViewer';
import UserEditModal from '../components/UserEditModal'; // Импортируем модалку редактирования пользователя
import PasswordResetModal from '../components/PasswordResetModal';

const UserDetails = () => {
    const { id } = useParams();
    const [user, setUser] = useState(null);
    const [lessons, setLessons] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [isEditModalOpen, setIsEditModalOpen] = useState(false); // Состояние для модального окна
    const [isPasswordResetModalOpen, setIsPasswordResetModalOpen] = useState(false);

    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        return new Intl.DateTimeFormat('ru-RU', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            weekday: 'long',
            hour: '2-digit',
            minute: '2-digit'
        }).format(date);
    };

    const fetchUserDetails = async () => {
        try {
            const userResponse = await axiosInstance.get(`/v1/users/${id}`);
            const lessonsResponse = await axiosInstance.get(`/v1/users-lessons/${id}`);
            setUser(userResponse.data.user);
            setLessons(lessonsResponse.data.lessons);
            setLoading(false);
        } catch (error) {
            setError('Failed to fetch user details or lessons.');
            setLoading(false);
        }
    };

    const toggleAttendance = async (lessonId, attended) => {
        try {
            await axiosInstance.post(`/v1/lessons/admin/attend`, { user_id: user.id, lesson_id: lessonId, status: attended });
            fetchUserDetails();
        } catch (error) {
            alert('Failed to update attendance.');
        }
    };

    const handleDeleteLesson = async (lessonId) => {
        try {
            await axiosInstance.post(`/v1/lessons/admin/enroll-cancel`, { user_id: user.id, lesson_id: lessonId });
            alert('Lesson deleted successfully');
            fetchUserDetails();
        } catch (error) {
            alert('Failed to delete lesson.');
        }
    };

    useEffect(() => {
        fetchUserDetails();
    }, [id]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div className="text-red-500">{error}</div>;
    }

    return (
        <div>
            <p className="text-[#707EAE]">Pages / User Details / {id}</p>
            <h2 className="text-3xl font-bold text-[#2B3674]">User Details</h2>

            {user && (
                <div className="bg-[#1F2937] p-6 rounded shadow-md mt-6 text-[#F9FAFB]">
                    <img
                        src={user.image_url == "" ? UserAva : user.image_url}
                        alt={`${user.name} ${user.surname}`}
                        className="w-24 h-24 rounded-full mb-4 border-4 border-[#4B5563]"
                    />
                    <p><strong>Name:</strong> {user.name} {user.surname}</p>
                    <p><strong>Email:</strong> {user.email}</p>
                    <p><strong>Role:</strong> {user.role}</p>
                    <p><strong>Major:</strong> {user.major}</p>
                    <p><strong>Year:</strong> {user.year}</p>
                    <p><strong>Created at:</strong> {formatDate(user.created_at)}</p>
                    
                    {/* Кнопка для открытия модалки редактирования */}
                    <button 
                        className="bg-blue-500 text-white px-4 py-2 rounded mt-4"
                        onClick={() => setIsEditModalOpen(true)}
                    >
                        Edit User
                    </button>
                    
                    {/* Кнопка для сброса пароля */}
                    <button 
                        className="bg-yellow-500 text-white px-4 py-2 rounded ml-4 mt-4"
                        onClick={() => setIsPasswordResetModalOpen(true)}
                    >
                        Reset Password
                    </button>
                </div>
            )}

            {/* Модальное окно редактирования */}
            {isEditModalOpen && (
                <UserEditModal
                    user={user}
                    onClose={() => setIsEditModalOpen(false)}
                    onSave={fetchUserDetails} 
                />
            )}

            {/* Модальное окно сброса пароля */}
            {isPasswordResetModalOpen && (
                <PasswordResetModal
                    userId={user.id}
                    onClose={() => setIsPasswordResetModalOpen(false)}
                />
            )}

            {lessons?.length > 0 ? (
                <div className="mt-8">
                    <h3 className="text-2xl font-bold text-[#2B3674]">Lessons</h3>
                    <table className="min-w-full bg-white">
                        <thead className="bg-gray-800 text-white">
                            <tr>
                                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Order</th>
                                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Lesson ID</th>
                                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Lesson</th>
                                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Teacher</th>
                                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Start Date</th>
                                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Location</th>
                                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Attended</th>
                                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Actions</th>
                            </tr>
                        </thead>
                        <tbody className="text-gray-700">
                            {lessons.map((lesson, index) => (
                                <tr key={lesson.lesson_id} className="bg-gray-100">
                                    <td className="text-left py-3 px-4">{index + 1}</td>
                                    <td className="text-left py-3 px-4">{lesson.lesson_id}</td>
                                    <td className="text-left py-3 px-4">{lesson.lesson_title}</td>
                                    <td className="text-left py-3 px-4">{lesson.teacher_name} {lesson.teacher_surname}</td>
                                    <td className="text-left py-3 px-4">{formatDate(lesson.lesson_start_date)}</td>
                                    <td className="text-left py-3 px-4">{lesson.lesson_location}</td>
                                    <td className="text-left py-3 px-4">
                                        {lesson.student_attended ? 'Yes' : 'No'}
                                    </td>
                                    <td className="text-left py-3 px-4 flex space-x-2">
                                        <button 
                                            className={`px-2 py-1 rounded text-white ${lesson.student_attended ? 'bg-red-500' : 'bg-green-500'}`}
                                            onClick={() => toggleAttendance(lesson.lesson_id, !lesson.student_attended)}>
                                            {lesson.student_attended ? 'Mark Absent' : 'Mark Present'}
                                        </button>
                                        <button 
                                            className="bg-gray-500 text-white px-2 py-1 rounded flex items-center"
                                            onClick={() => handleDeleteLesson(lesson.lesson_id)}>
                                            <FaTrashAlt /> 
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <p className="mt-4 text-[#2B3674]">No lessons found.</p>
            )}

            <LogViewer url={`/v1/logs/auth?email=${user.email}`} title="Auth" />
            <LogViewer url={`/v1/logs/enroll?user_id=${user.id}`} title="Enroll" />
            <LogViewer url={`/v1/logs/cancel-enroll?user_id=${user.id}`} title="Cancel Enroll" />
            <LogViewer url={`/v1/logs/attend?user_id=${user.id}`} title="Attendance" />
        </div>
    );
};

export default UserDetails;